<template>
  <div
    v-bind:class="{
      'text-primary': primary,
      'text-secondary': secondary,
      'text-danger': danger,
      'text-warning': warning,
      'text-success': success,
      'text-info': info,
      'text-light': light,
      'text-dark': dark
    }"
    v-bind:style="{ 'margin-top': margin + 'rem', 'margin-bottom': margin + 'rem' }"
    class="text-center busy"
  >
    <b-spinner
      class="align-middle"
      v-bind:style="{ width: size + 'rem', height: size + 'rem' }"
    ></b-spinner>
    <strong v-if="text" class="ml-3">{{ text }}</strong>
  </div>
</template>

<style lang="scss">
.busy {
  opacity: 0.7;
}
</style>

<script>
export default {};
</script>

<script>
export default {
  name: "Busy",
  props: {
    text: String,
    primary: Boolean,
    secondary: Boolean,
    danger: Boolean,
    warning: Boolean,
    success: Boolean,
    info: Boolean,
    light: Boolean,
    dark: Boolean,
    size: {
      type: Number,
      default: 2
    },
    margin: {
      type: Number,
      default: 0.5
    }
  }
};
</script>
