var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center busy",class:{
    'text-primary': _vm.primary,
    'text-secondary': _vm.secondary,
    'text-danger': _vm.danger,
    'text-warning': _vm.warning,
    'text-success': _vm.success,
    'text-info': _vm.info,
    'text-light': _vm.light,
    'text-dark': _vm.dark
  },style:({ 'margin-top': _vm.margin + 'rem', 'margin-bottom': _vm.margin + 'rem' })},[_c('b-spinner',{staticClass:"align-middle",style:({ width: _vm.size + 'rem', height: _vm.size + 'rem' })}),(_vm.text)?_c('strong',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.text))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }