<template>
  <b-modal
    id="info-modal"
    ref="infoModalRef"
    v-model="isOpen"
    ok-only
    content-class="shadow"
    ok-variant="secondary"
    ok-title="Close"
    size="lg"
    title="More details"
    @hidden="handleCancel"
    lazy
  >
    <div v-if="moreInfoEmail">
      <b-row>
        <b-col cols="12">
          <h4>Generic details</h4>
        </b-col>
        <b-col>
          <p>
            <b>ID:</b>
            {{ moreInfoEmail.id }}
          </p>
          <p>
            <b>SortKey:</b>
            {{ moreInfoEmail.sortKey }}
          </p>
          <p v-if="moreInfoEmail.bcc">
            <b>BCC:</b>
            <span class="ml-1">{{ moreInfoEmail.bcc }}</span>
          </p>
        </b-col>
        <b-col>
          <p>
            <b>Type:</b>
            <span class="ml-1">{{ moreInfoEmail.type }}</span>
          </p>
          <p>
            <b>Subject:</b>
            <span class="ml-1">{{ moreInfoEmail.subject }}</span>
          </p>
          <p>
            <b>Version:</b>
            {{ moreInfoEmail.version }}
          </p>
          <p v-if="moreInfoEmail.cc">
            <b>CC:</b>
            <span class="ml-1">{{ moreInfoEmail.cc }}</span>
          </p>
        </b-col>
      </b-row>

      <b-row v-if="(moreInfoEmail.type = 'SHARE_SECOND')">
        <b-col cols="12">
          <h4>Type details: {{ formatCoboldCase(moreInfoEmail.type) }}</h4>
        </b-col>
        <b-col>
          <p v-if="moreInfoEmail.userName">
            <b>Recipient name:</b>
            <span class="ml-1">{{ moreInfoEmail.userName }}</span>
          </p>
          <p>
            <b>Magic Link:</b>
            <a class="ml-1">{{ moreInfoEmail.magicLink }}</a>
          </p>
        </b-col>
        <b-col>
          <p>
            <b>Section 1:</b>
            <span class="ml-1">{{ moreInfoEmail.sectionOne }}</span>
          </p>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <p>We couldn't find any additional information to show</p>
    </div>
  </b-modal>
</template>

<style lang="scss">
@import "@/styles/common.scss";
</style>

<script>
import FormatHelper from "@/helper/formathelper";

export default {
  name: "ScheduledEmailsModal",
  props: {
    moreInfoEmail: Object
  },
  computed: {
    isOpen: {
      get() {
        if (this.moreInfoEmail) {
          return true;
        } else {
          return false;
        }
      },
      set(value) {
        if (value) {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  methods: {
    formatCoboldCase: FormatHelper.formatCoboldCase,

    handleCancel() {
      this.$emit("cancelled");
    }
  }
};
</script>