<template>
  <div class="mx-4">
    <div>
      <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />

      <b-modal
        id="delete-scheduled-email-modal"
        v-model="deleteModal"
        title="Deleting scheduled email"
        @hidden="handleCancel"
        @ok="deleteScheduledEmails(deleteEmail)"
        lazy
      >
        <p class="my-2" v-if="selectedEmails.length > 1">
          Are you sure you want to delete {{ selectedEmails.length }} emails ?
        </p>
        <p class="my-2" v-else>Are you sure you want to delete the email?</p>
      </b-modal>

      <ScheduledEmailsModal
        :moreInfoEmail="moreInfoEmail"
        @cancelled="moreInfoEmail = null"
      ></ScheduledEmailsModal>

      <b-container fluid>
        <h1 class="mw-50 mx-5">Scheduled Emails</h1>

        <!-- filter section -->
        <b-form-group class="mb-4 mx-5">
          <b-row>
            <b-col lg="10" class="mx-auto">
              <b-input-group>
                <b-form-input
                  list="email-suggestions"
                  v-model="recipient"
                  placeholder="Recipient email"
                  @keydown.native.enter="getEmails()"
                  @keydown.native.esc="recipient = null"
                ></b-form-input>
                <b-button
                  variant="primary"
                  class="mx-2"
                  @click="getEmails()"
                  :disabled="busyDeleting"
                  >Search</b-button
                >
                <b-button variant="secondary" @click="recipient = null">Clear</b-button>

                <datalist id="email-suggestions">
                  <option v-bind:key="address" v-for="address in emailAnddresses">
                    {{ recipient }}{{ address }}
                  </option>
                </datalist>
              </b-input-group>
            </b-col>
          </b-row>
        </b-form-group>

        <!--Pagination Top-->
        <b-row>
          <b-col lg="10" class="mx-auto">
            <b-row class="pagination mx-5" align-v="end">
              <b-col>
                <p class="mb-1">
                  <b-button
                    v-if="selectedEmails.length > 0"
                    :disabled="busyDeleting"
                    variant="danger"
                    class="d-inline-block mr-2 mb-2"
                    @click="confirmDeletion(selectedEmails)"
                  >
                    <font-awesome-icon :icon="['fas', 'trash']" size="lg" class="mr-1" />
                    Delete ({{ selectedEmails.length }})
                  </b-button>
                  <span class="mr-2">Emails count: {{ scheduledEmails.length }}</span>
                </p>
              </b-col>
              <b-col class="text-right">
                <p class="mb-1">
                  <span>Emails per page:</span>
                  <span class="ml-1">
                    <span v-if="perPage == 50">50</span>
                    <b-link v-else @click="perPage = 50">50</b-link>,
                    <span v-if="perPage == 100">100</span>
                    <b-link v-else @click="perPage = 100">100</b-link>,
                    <span v-if="perPage == 500">500</span>
                    <b-link v-else @click="perPage = 500">500</b-link>,
                    <span v-if="perPage == allItems">All</span>
                    <b-link v-else @click="perPage = allItems">All</b-link>
                  </span>
                </p>

                <b-pagination
                  class="float-right"
                  v-if="rows > perPage"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!--Table-->
        <b-row class="mx-5">
          <b-col lg="10" class="mx-auto">
            <b-table
              class="scheduled-emails-table"
              busy.sync="busy"
              show-empty
              :items="scheduledEmails"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template slot="empty">
                <div v-if="busy" class="text-center">
                  <Busy primary text="Loading..." />
                </div>
                <div v-else class="text-center">Search for deals to show.</div>
              </template>

              <template v-slot:cell(actions)="row">
                <div :id="row.item.id">
                  <div
                    v-if="selectedEmails.length == 0 && !busyDeleting"
                    class="delete-button d-inline-block mb-1 mr-3"
                    @click="confirmDeletion([row.item])"
                  >
                    <font-awesome-icon :icon="['fas', 'trash-alt']" />
                  </div>
                  <div v-else-if="busyDeleting" class="d-inline-block mb-1 mr-3">
                    <Busy primary :size="1.2" />
                  </div>
                  <div
                    v-else
                    class="delete-button disabled d-inline-block mb-1 mr-3"
                    aria-disabled="true"
                  >
                    <font-awesome-icon :icon="['fas', 'trash-alt']" />
                  </div>

                  <div class="open-info-modal d-inline-block mb-1" @click="openInfoModal(row.item)">
                    <font-awesome-icon :icon="['fas', 'info-circle']" />
                  </div>
                </div>
              </template>

              <template v-slot:cell(checkbox)="row">
                <div class="w-100 h-100" @click="checkboxAction(row.item)">
                  <input
                    type="checkbox"
                    class="selection-checkbox d-inline"
                    :value="row.item"
                    v-model="selectedEmails"
                  />
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <!--Pagination bottom-->
        <b-row class="pagination mx-5" align-v="end">
          <b-col lg="10" class="mx-auto">
            <b-pagination
              class="float-right"
              v-if="rows > perPage"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";

.open-info-modal:hover {
  cursor: pointer;
}

.delete-button:hover,
.open-info-modal:hover {
  cursor: pointer;
}

.delete-button:hover > .fa-trash-alt {
  color: $color-red;
}

.open-info-modal:hover > .fa-info-circle {
  color: $color-blue-darker2;
}

.delete-button.disabled,
.delete-button.disabled:hover > .fa-trash-alt {
  color: $color-grey-lighter1;
  cursor: initial;
}

.scheduled-emails-table > tbody > tr:hover {
  box-shadow: 0px 1px 7px 0px $color-grey-lighter2;
  cursor: pointer;
}
</style>

<script>
import FormatHelper from "@/helper/formathelper";
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import Busy from "@/components/Busy";
import ScheduledEmailsModal from "@/components/ScheduledEmailsModal";

export default {
  name: "ScheduledEmails",
  components: {
    Busy,
    ScheduledEmailsModal
  },
  data() {
    return {
      title: "Scheduled Emails",
      busy: false,
      busyDeleting: false,
      showInfoModal: false,
      moreInfoEmail: null,

      deleteModal: false,
      deleteEmail: [],

      perPage: 50,
      currentPage: 1,

      scheduledEmails: [],
      fields: [
        { key: "actions", label: "", class: "text-center" },
        { key: "checkbox", label: "", class: "text-center align-middle" },
        {
          key: "sendFromDateTime",
          label: "Send after",
          sortable: true,
          formatter: "formatDateTimeToSec"
        },
        {
          key: "to",
          label: "Recipient",
          sortable: true
        },
        { key: "templateName", label: "Template", sortable: true },
        {
          key: "createdDate",
          label: "Created",
          sortable: true,
          formatter: "formatDate"
        },
        {
          key: "ttl",
          label: "Delete date",
          formatter: "formatEpochToHumanReadableTime",
          sortable: true
        }
      ],

      recipient: null,
      emailAnddresses: ["@gmail.com", "@gmail.co.uk"],
      selectedEmails: [],
      selectedEmailsHistory: []
    };
  },
  computed: {
    rows() {
      return this.scheduledEmails.length;
    }
  },
  methods: {
    formatDateTimeToSec: FormatHelper.formatDateTimeToSec,
    formatDate: FormatHelper.formatDate,
    formatEpochToHumanReadableTime: FormatHelper.formatEpochToHumanReadableTime,
    allItems: ApiHelper.ALL_ITEMS,

    checkboxAction(email) {
      if (this.selectedEmails.indexOf(email) == -1) {
        this.selectedEmails.push(email);
      } else {
        this.selectedEmails.splice(this.selectedEmails.indexOf(email), 1);
      }

      this.toggleSelectedRows([email]);
    },

    toggleSelectedRows(emails) {
      emails.forEach(email => {
        var tr = document.getElementById(email.id).closest("tr");
        tr.bgColor = tr.bgColor === "" ? "#ECECEC" : "";
      });
    },

    openInfoModal(email) {
      this.moreInfoEmail = email;
      this.showInfoModal = true;
    },

    confirmDeletion(emails) {
      this.deleteModal = true;
      this.deleteEmail = [...emails];
    },

    async getEmails() {
      this.busy = true;

      var query = "";
      if (this.recipient) {
        query = "?recipient=" + this.recipient;
      }

      try {
        const client = await ApiHelper.http();
        const response = await client.get(`${ApiHelper.endPoint()}email/scheduled${query}`);
        if (response.data.status === "success") {
          Console.log(response.data);
          this.scheduledEmails = [];
          this.selectedEmails = [];

          this.scheduledEmails = [...response.data.scheduledEmails];
          Console.log("Scheduled emails: ", response.data.scheduledEmails);
        } else {
          this.showMessage("A problem occured while extracting the scheduled emails.", "warning");
        }
      } catch (error) {
        this.showMessage("A problem occured while extracting the scheduled emails.", "warning");
        Console.error(error);
      } finally {
        this.busy = false;
      }
    },

    async deleteScheduledEmails(emails) {
      let dataObject = new Object();
      dataObject.emails = [...emails];
      this.busyDeleting = true;
      try {
        const client = await ApiHelper.http();
        const response = await client.post(`${ApiHelper.endPoint()}email`, dataObject);

        Console.log("Response: ", response);
        if (response.data.status === "success") {
          this.toggleSelectedRows(this.selectedEmails);
          this.selectedEmails = [];
          emails.forEach(email => {
            this.scheduledEmails.splice(this.scheduledEmails.indexOf(email), 1);
          });

          this.showMessage("Scheduled email(s) deleted successfully", "success");
          return true;
        } else {
          this.showMessage("There was a problem deleting the scheduled email(s).", "warning");
          return false;
        }
      } catch (error) {
        Console.error(error);
        this.showMessage("There was a problem deleting the scheduled email(s).", "warning");
        return false;
      } finally {
        this.busyDeleting = false;
      }
    },

    //MESSAGE FORMAT
    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    handleCancel() {
      this.$emit("cancelled");
    }
  }
};
</script>
